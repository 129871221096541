<template>
  <button type="button" class="btn-visible-button" :class="{show:isActive}" @click="isActive=!isActive">
    <span class="titleText">{{btnText}}</span>
    <span class="contentText">{{btnData}}</span>
  </button>
</template>

<script>
export default {
  name: 'BtnVisibleToggle',
  props: ['btnText', 'btnData'],
  data () {
    return {
      isActive: false
    }
  }
}
</script>

<style scoped>
button{
  font-size:12px;
}
  .btn-visible-button{
    height: 25px;
    font-weight: 700;
    border: 1px solid var(--puple-dark);
    background: var(--puple-dark);
    cursor: pointer;
    padding: 0px 6px;
    color:#fff;
  }

  .btn-visible-button:hover {
    background: #fff;
    color: var(--puple-dark);
  }
  .btn-visible-button.show{
    border:transparent;
    background:transparent;
  }

  .btn-visible-button.show .titleText{
    display:none;
  }

  .contentText {
    display:none;
    color:#000;
    font-weight: normal;
  }
  .btn-visible-button.show .contentText{
    display:inline;
  }
</style>
